<template>
  <div class="content">
    <div class="selOppTitle">
      <p>选择乘机人</p>
      <p v-if="isCompletion" @click="Completion">完成</p>
    </div>
    <div class="oppListWrap">
      <div v-for="(item,index) in TakeOppList" :key="index" class="oppList">
        <div class="oppLists">
          <img
            :src="item.isSel ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuipm6F6foTrL1609154518.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/19/yuelvhuiGQvkGohOde1609154493.png'"
            alt=""
            @click="selOpp(item)"
          >
          <div class="oppInfo">
            <p>{{ item.name }}</p>
            <p>{{ item.idTypeText }}{{ item.idCard }}</p>
            <p>手机号 {{ item.phone }}</p>
          </div>
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-30/16/yuelvhuiVMs60BTU8X1609317053.png"
            alt=""
            @click="EditorOpp(item)"
          >
        </div>
        <div class="van-hairline--bottom" />
      </div>
    </div>
    <div class="addOppInfoWrap" @click="goAddInfo">
      <div class="addOppInfo">
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/23/yuelvhuirJYzTEB5cM1609254853.png"
          alt=""
        >
        <p>添加旅客信息</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTakeOppList
} from '@/services/comeOn.js'

export default {
  name: 'SelOpp',
  data() {
    return {
      TakeOppList: [],
      isCompletion: true, // 显示完成
      selTakeOppList: []// 选中的乘机人列表
    }
  },
  created() {

  },
  mounted() {
    this.getOpportunity()// get乘机人列表
  },
  methods: {
    // 添加旅客信息
    goAddInfo() {
      this.$router.push({
        path: '/addOpp'
      })
    },
    // get乘机人
    getOpportunity() {
      getTakeOppList({
        mid: window.localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].isSel = false
          }
          this.TakeOppList = res.data.list
          this.selTakeOppList = JSON.parse(window.localStorage.getItem('selTakeOppList'))
          for (let i = 0; i < this.TakeOppList.length; i++) {
            for (let j = 0; j < this.selTakeOppList.length; j++) {
              if (this.TakeOppList[i].id === this.selTakeOppList[j].id) {
                this.TakeOppList[i].isSel = true
              }
            }
          }
        }
      })
    },
    // 选择联系人
    selOpp(item) {
      item.isSel = !item.isSel
      if (item.isSel) {
        this.selTakeOppList.push(item)
        window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
      } else {
        for (let i = 0; i < this.selTakeOppList.length; i++) {
          if (this.selTakeOppList[i].id === item.id) {
            this.selTakeOppList.splice(i, 1)
          }
        }
        window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
      }
    },
    // 编辑乘机人
    EditorOpp(item) {
      this.$router.push({
        path: '/addOpp',
        query: {
          id: item.id
        }
      })
    },
    // 选择完成
    Completion() {
      window.localStorage.setItem('selTakeOppList', JSON.stringify(this.selTakeOppList))
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;

  .selOppTitle {
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 15px;
    display: flex;
    font-size: 17px;
    color: #333333;
    text-align: center;
    position: relative;

    p:nth-child(1) {
      position: absolute;
      margin-left: 50%;
      left: -41px;
      font-weight: 600;
    }

    p:nth-child(2) {
      margin-left: auto;
    }
  }

  .oppListWrap {
    img:nth-child(1) {
      width: 17px;
      height: 17px;
      margin-right: 17px;
    }

    .oppList {
      .oppLists {
        display: flex;
        padding: 20px 15px;
        color: #333333;

        p:nth-child(1) {
          font-size: 17px;
          font-weight: 600;
        }

        p:nth-child(2), p:nth-child(3) {
          font-size: 14px;
          margin-top: 10px;
        }
      }

    }

    img:nth-child(3) {
      width: 21px;
      height: 21px;
      margin-left: auto;
      margin-top: 21px;
    }
  }

  .addOppInfoWrap {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background-color: #E33F44;
    display: flex;
    justify-content: center;

    .addOppInfo {
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-top: 20px;
      }

      p {
        font-size: 17px;
        color: #ffffff;
        margin-left: 10px;
      }
    }
  }
}
</style>
